import React from 'react'
import "./NewCampaignDetails.css";
import {formatDateStringReport} from "../../../utils/validations";

export const NewCampaignDetails = ({
                                       date = "",
                                       price = "",
                                       totalFollowers = "",
                                       countOfAccounts = "",
                                       countOfVideos = ""
                                   }) => {
    return (
        <div className="account-client-campaign-strategy-details">
            <div className="account-client-campaign-strategy-details-first">
                <p>Date Submitted: <span>{formatDateStringReport(date)}</span></p>
                <p>Price: <span>{price}€</span></p>
            </div>
            <div className="account-client-campaign-strategy-details-second">
                <p>Combined Followers: <span>{totalFollowers}</span></p>
                <p>Posts & Stories: <span>{countOfAccounts}</span></p>
            </div>
            <div className="account-client-campaign-strategy-details-third">
                <p>Video Options: <span>{countOfVideos}</span></p>
            </div>
        </div>
    )
}
