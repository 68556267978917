import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from "react-router-dom";
import {NotificationContainer, NotificationManager} from "react-notifications";
import axios from "axios";
import {decodeAndDecrypt, encryptAndEncode} from "../../../../../../utils/crypt";
import Loading from "../../../../../form/PageLoading/pageLoading";
import {useDispatch, useSelector} from "react-redux";
import {
    clearState,
    setCreatedDate,
    setFullState,
    setPrice,
    setTotalFollowers
} from "../../../../../../redux/slice/proposal-system";
import {setFullState as setFullStateCreatePromo} from "../../../../../../redux/slice/create-promo";
import TitleSection from "../../../../../TitleSection";
import arrow from "../../../../../../images/icons/arrow.svg";
import shareIcon from "../../../../../../images/icons/Share.svg";
import watch from "../../../../../../images/icons/view 1.svg";
import {NewCampaignDetails} from "../../../../../form/NewCampaignDetails/NewCampaignDetails";
import {AccountClientSharedCampaignTable} from "./AccountClientSharedCampaignTable";
import StandardButton from "../../../../../form/StandardButton";

export const AccountClientSharedCampaignPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const campaignId = decodeAndDecrypt(useParams().campaignId, process.env.REACT_APP_PROPOSAL_SHARE_SECRET_KEY);
    const campaignName = useSelector(state => state.proposalSystem.campaignName);
    const totalFollowers = useSelector(state => state.proposalSystem.totalFollowers);
    const price = useSelector(state => state.proposalSystem.price);
    const addedAccounts = useSelector(state => state.proposalSystem.addedAccounts);
    const campaignContent = useSelector(state => state.proposalSystem.campaignContent);
    const createdDate = useSelector(state => state.proposalSystem.createdDate);
    const selectedSocialMedia = useSelector(state => state.proposalSystem.selectedSocialMedia);
    
    const [isLoading, setIsLoading] = useState(true);
    const [isGenresShown, setIsGenresShown] = useState(false);
    const [isCountriesShown, setIsCountriesShown] = useState(false);

    useEffect(() => {
        getData();
    }, [campaignId]);

    const getData = async () => {
        try {
            const result = await axios.get(`${process.env.REACT_APP_SERVER}/proposal-system/get-proposal-campaign/${campaignId}`);

            if (result.data.status === 200) {
                setIsLoading(false);
                dispatch(setFullState({
                    campaignId: campaignId,
                    selectedSocialMedia: result.data.proposalCampaign.socialMedia,
                    selectedClientId: result.data.proposalCampaign.clientId,
                    campaignName: result.data.proposalCampaign.campaignName,
                    addedAccounts: result.data.proposalCampaign.selectInfluencers,
                    campaignContent: result.data.proposalCampaign.videos
                }))
                dispatch(setTotalFollowers(result.data.proposalCampaign.totalFollowers));
                dispatch(setPrice(result.data.proposalCampaign.publicPrice));
                dispatch(setCreatedDate(result.data.proposalCampaign.createdAt));
                return;
            }

            NotificationManager.error('Failed to load campaign', 'Error', 3000);
        } catch (err) {
            NotificationManager.error('Failed to load campaign', 'Error', 3000);
            console.error(err);
        }
    };

    const handleCopyLink = () => {
        try {
            navigator.clipboard.writeText(window.location.href);
            NotificationManager.success('Link copied to clipboard', '', 3000);
        } catch (err) {
            NotificationManager.error('Failed to copy link to clipboard');
        }
    };

    const approveCampaign = () => {
        dispatch(setFullStateCreatePromo({
            currentWindow: 5,
            currency: "€",
            amount: price,
            selectPrice: {
                variant: 0,
                price: price,
            },
            selectInfluencers: addedAccounts,
            selectPriceInfluencers: addedAccounts,
            campaignName: campaignName,
            paymentType: "",
            paymentStatus: "wait",
            createdAt: createdDate,
            videos: campaignContent,
            socialMedia: selectedSocialMedia,
            canGoBack: false,
        }))
        navigate(`/account/client/promo/${selectedSocialMedia}`);
    };
    
    const sendMailAboutChanges = async () => {
        try {
            const encryptedCampaignId = encryptAndEncode(campaignId, process.env.REACT_APP_API_ENCRYPTION_SECRET_KEY);
            const result = await axios.post(`${process.env.REACT_APP_SERVER}/proposal-system/send-email-to-admin-changes/${encryptedCampaignId}/${campaignName}`);
            
            if (result.data.status === 200) {
                NotificationManager.success('Email sent', '', 3000);
                return;
            }
            
            NotificationManager.error('Failed to send email', 'Error', 3000);
        } catch (err) {
            NotificationManager.error('Failed to send email', 'Error', 3000);
            console.error(err);
        }
    };
    
    if (isLoading) return <Loading/>;
    return (
        <section className="proposal-system">
            <div className="proposal-system-title-with-btn">
                <div id="back-btn" onClick={() => {
                    dispatch(clearState());
                    navigate("/account/client")
                }}>
                    <img src={arrow} alt="" style={{transform: "rotate(180deg)"}}/>
                </div>
                <div id="title">
                    <TitleSection title="Campaign" span="strategy"/>
                </div>
                <div id="share" onClick={() => handleCopyLink()}>
                    <img src={shareIcon} alt={""}/>
                </div>
            </div>

            <div className="proposal-system-influencers">
                <div className="details">
                    <div className="details-campaign-name">
                        <input
                            type="text"
                            placeholder="Campaign Name"
                            value={campaignName}
                            readOnly={true}
                        />
                    </div>

                    <div>
                        <NewCampaignDetails date={createdDate} totalFollowers={totalFollowers} price={price}
                                            countOfAccounts={addedAccounts.length}
                                            countOfVideos={campaignContent.length}/>
                    </div>
                </div>
            </div>

            <div className="proposal-system-shared-campaign-buttons">
                <StandardButton style={{width: 203, height: 41, fontSize: 15, padding: 0}} isBlue={true}
                                onClick={approveCampaign}
                                text={"APPROVE CAMPAIGN"}/>
                <StandardButton style={{width: 203, height: 41, fontSize: 15, padding: 0}} isBlue={true}
                                onClick={sendMailAboutChanges}
                                text={"REQUEST CHANGE"}/>
            </div>

            <div className={`hide-genres-countries-buttons ${isGenresShown && isCountriesShown && "hidden"}`}>
                <button className={`btn ${isGenresShown && 'hidden'}`} onClick={() => setIsGenresShown(!isGenresShown)}>
                    <img src={watch} alt={""}/>
                    <p>SHOW MUSIC GENRES</p>
                </button>

                <button className={`btn ${isCountriesShown && 'hidden'}`}
                        onClick={() => setIsCountriesShown(!isCountriesShown)}>
                    <img src={watch} alt={""}/>
                    <p>SHOW AUDIENCE LOCATION</p>
                </button>
            </div>

            <div
                className={`proposal-system-shared-campaign-table-container ${(isGenresShown || isCountriesShown) ? "wider" : ""}`}>
                {addedAccounts.length > 0 &&
                    <AccountClientSharedCampaignTable isGenresShown={isGenresShown}
                                                      setIsCountriesShown={setIsCountriesShown}
                                                      setIsGenresShown={setIsGenresShown}
                                                      isCountriesShown={isCountriesShown}/>}
            </div>

            <NotificationContainer/>
        </section>
    )
}
