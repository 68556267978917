import React from 'react'
import {getSocialMediaIcon} from "../../../../../../../utils/typeOfSocialAccounts";
import editIcon from "../../../../../../../images/icons/edit 1.svg";
import sendMessage from "../../../../../../../images/icons/mail (1) 1.svg";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {setFullState} from "../../../../../../../redux/slice/proposal-system";
import {generateMongoObjectId} from "../../../../../../../utils/generateId";

export const CampaignItem = ({campaign}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const handleEditButton = () => {
        const state = {
            campaignId: campaign._id,
            selectedSocialMedia: campaign.socialMedia,
            selectedClientId: campaign.client.clientId || "",
            campaignName: campaign.campaignName,
            addedAccounts: campaign.selectInfluencers.map((acc) => {
                return {
                    influencerId: acc.influencerId,
                    instagramUsername: acc.instagramUsername,
                    selectedVideo: acc.selectedVideo,
                    dateRequest: acc.dateRequest,
                }
            }) || [],
            campaignContent: campaign.videos || [{
                _id: generateMongoObjectId(),
                videoLink: "",
                postDescription: "",
                storyTag: "",
                swipeUpLink: "",
                specialWishes: ""
            }],
        }
        dispatch(setFullState(state));        
        navigate('/admin/proposal-system/manage-campaign');
    };
    
    return (
        <div className="campaign">
            <div className="campaign-icon-name-block">
                <img src={getSocialMediaIcon(campaign.socialMedia)} alt="N/A"/>
                <p>{campaign.campaignName}</p>
            </div>

            <div className="campaign-info-item">
                <span>Client Name:</span>
                <p>{campaign.client.firstName || "N/A"}</p>
            </div>

            <div className="campaign-info-item">
                <span>Followers:</span>
                <p>{campaign.totalFollowers || "N/A"}</p>
            </div>

            <div className="campaign-info-item">
                <span>Price:</span>
                <p>{campaign.price + "€" || "N/A"}</p>
            </div>

            <div className="campaign-info-item">
                <span>Public Price:</span>
                <p>{campaign.publicPrice + "€" || "N/A"}</p>
            </div>

            <div className="manage-buttons-block">
                <button onClick={() => handleEditButton()}>
                    <img src={editIcon} alt="edit"/>
                </button>
                <button>
                    <img src={sendMessage} alt="sendMessage"/>
                </button>
            </div>
        </div>
    )
}
