import React, {useEffect, useState} from 'react';
import {SelectClient} from "./components/SelectClient";
import {SelectInfluencers} from "./components/SelectInfluencers";
import "../../../../../styles/components/_proposal-system.scss";
import {NotificationContainer, NotificationManager} from "react-notifications";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {
    addAccount, linkCampaignContentItemToInfluencer,
    setCurrentWindowIndex,
    setIsAddInfluencerToCampaignModalOpen, setIsSelectCampaignContentItemModalOpen
} from "../../../../../redux/slice/proposal-system";
import {SelectSocialMedia} from "./components/SelectSocialMedia";
import {AccountModalInfo} from "./components/form/AccountModalInfo";
import {AddInfluencerToCampaignModal} from "../../../../form/AddInfluencerToCampaignModal/AddInfluencerToCampaignModal";
import {SelectContent} from "./components/SelectContent";
import {SelectCampaignContentItemModal} from "./components/form/SelectCampaignContentItemModal";

export const AdminCreateCampaignContainer = () => {
    const dispatch = useDispatch();

    const {
        currentWindowIndex,
        selectedSocialMedia,
        selectedClientId,
        addedAccounts,
        campaignContent,
        isAccountInfoOpen,
        isAddInfluencerToCampaignModalOpen,
        isSelectCampaignContentItemModalOpen,
    } = useSelector(state => state.proposalSystem);

    const [clients, setClients] = useState([]);
    const [influencers, setInfluencers] = useState([]);

    useEffect(() => {
        getDataClients();
        if (selectedSocialMedia && influencers.length === 0) {
            getDataInfluencers();
        }
    }, []);

    //получает всех клиентов и инфлюенсеров
    const getDataClients = async () => {
        try {
            const result = await axios.get(`${process.env.REACT_APP_SERVER}/proposal-system/get-all-clients`);

            if (result.data.status === 200) {
                setClients(result.data.clients);
            } else {
                throw new Error("Error getting clients");
            }
        } catch (error) {
            NotificationManager.error("Error getting clients", "Error", 2000);
            console.error(error);
        }
    }
    const getDataInfluencers = async () => {
        try {
            const result = await axios.get(`${process.env.REACT_APP_SERVER}/proposal-system/get-all-brand-accounts/${selectedSocialMedia}`);

            if (result.data.status === 200) {
                setInfluencers(result.data.influencers);
            } else {
                throw new Error("Error getting influencers");
            }
        } catch (error) {
            NotificationManager.error("Error getting influencers", "Error", 2000);
            console.error(error);
        }
    };

    //переход между страницами
    const nextPage = () => {
        if (currentWindowIndex === 0) {
            dispatch(setCurrentWindowIndex(1));
            getDataInfluencers();
        } else {
            dispatch(setCurrentWindowIndex(Math.min(currentWindowIndex + 1, 3)));
        }
    };
    const prevPage = () => {
        dispatch(setCurrentWindowIndex(Math.max(currentWindowIndex - 1, 0)));
    };

    //функции которые закрывают модальные окна
    const setCloseIsAddInfluencerToCampaignModalOpen = () => {
        dispatch(setIsAddInfluencerToCampaignModalOpen(false));
    };
    const setCloseIsSelectCampaignContentItemModalOpen = () => {
        dispatch(setIsSelectCampaignContentItemModalOpen(false, "", ""));
    };

    //добавляет инфлюенсера в кампанию
    const addInfluencerToCampaign = (influencer) => {
        dispatch(addAccount({influencerId: influencer.influencerId, instagramUsername: influencer.instagramUsername, dateRequest: influencer.dateRequest}));
    };
    
    //связывает инфлюенсера с контентом
    const selectCampaignContentItemForInfluencer = (videoLink) => {
        dispatch(linkCampaignContentItemToInfluencer(videoLink));
    };

    return (
        <section className="proposal-system-page">
            <div className="proposal-system-navigation-container">
                <div className="proposal-system-navigation">
                    <button onClick={prevPage} disabled={currentWindowIndex === 0}>{"Back"}</button>
                </div>
                <div>
                    <div className={`page ${currentWindowIndex === 0 ? 'enter' : 'exit-left'}`}>
                        {currentWindowIndex === 0 && <SelectSocialMedia/>}
                    </div>
                    <div className={`page ${currentWindowIndex === 1 ? 'enter' : 'exit-right'}`}>
                        {currentWindowIndex === 1 && <SelectClient clients={clients}/>}
                    </div>
                    <div className={`page ${currentWindowIndex === 2 ? 'enter' : 'exit-left'}`}>
                        {currentWindowIndex === 2 && <SelectContent/>}
                    </div>
                    <div className={`page ${currentWindowIndex === 3 ? 'enter' : 'exit-right'}`}>
                        {currentWindowIndex === 3 && <SelectInfluencers influencers={influencers}/>}
                    </div>
                </div>
                <div className="proposal-system-navigation">
                    <button onClick={nextPage}
                            disabled={currentWindowIndex === 3 || (currentWindowIndex === 1 && !selectedClientId) || (currentWindowIndex === 0 && !selectedSocialMedia)}>{"Next"}</button>
                </div>
            </div>

            {isAccountInfoOpen.isOpen && <AccountModalInfo/>}
            {isAddInfluencerToCampaignModalOpen &&
                <AddInfluencerToCampaignModal isOpen={isAddInfluencerToCampaignModalOpen}
                                              setClose={setCloseIsAddInfluencerToCampaignModalOpen}
                                              influencers={influencers.filter((acc) => {
                                                  return !addedAccounts.some((addedAcc) => addedAcc.influencerId === acc.influencerId && addedAcc.instagramUsername === acc.instagramUsername);
                                              })}
                                              selectInfluencer={addInfluencerToCampaign}/>}
            {isSelectCampaignContentItemModalOpen.isOpen &&
                <SelectCampaignContentItemModal isOpen={isSelectCampaignContentItemModalOpen.isOpen}
                                                campaignItems={campaignContent}
                                                selectItem={selectCampaignContentItemForInfluencer}
                                                setClose={setCloseIsSelectCampaignContentItemModalOpen}/>}
            <NotificationContainer/>
        </section>
    );
};