import {createSlice} from '@reduxjs/toolkit';
import {generateMongoObjectId} from "../../utils/generateId";

const saveStateToLocalStorage = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem("proposalSystemState", serializedState);
    } catch (e) {
        console.error("Error saving state to localStorage:", e);
    }
};

const loadStateFromLocalStorage = () => {
    try {
        const serializedState = localStorage.getItem("proposalSystemState");
        // console.log("serializedState", serializedState);
        return serializedState ? JSON.parse(serializedState) : {
            currentWindowIndex: 0,
            campaignId: "",
            selectedSocialMedia: "",
            selectedClientId: "",
            campaignName: "",
            addedAccounts: [],
            campaignContent: [{
                _id: generateMongoObjectId(),
                videoLink: "",
                postDescription: "",
                storyTag: "",
                swipeUpLink: "",
                specialWishes: ""
            }],
            totalFollowers: 0,
            price: 0,
            createdDate: "",
            isAccountInfoOpen: {isOpen: false, account: {}},
            isAddInfluencerToCampaignModalOpen: false,
            isSelectCampaignContentItemModalOpen: {isOpen: false, instagramUsername: "", influencerId: ""},
        };
    } catch (e) {
        console.error("Error loading state from localStorage:", e);
        return {
            currentWindowIndex: 0,
            campaignId: "",
            selectedSocialMedia: "",
            selectedClientId: "",
            campaignName: "",
            addedAccounts: [],
            campaignContent: [{
                _id: generateMongoObjectId(),
                videoLink: "",
                postDescription: "",
                storyTag: "",
                swipeUpLink: "",
                specialWishes: ""
            }],
            totalFollowers: 0,
            price: 0,
            createdDate: "",
            isAccountInfoOpen: {isOpen: false, account: {}},
            isAddInfluencerToCampaignModalOpen: false,
            isSelectCampaignContentItemModalOpen: {isOpen: false, instagramUsername: "", influencerId: ""},
        };
    }
};

const initialState = loadStateFromLocalStorage();

export const proposalSystemSlice = createSlice({
    name: "proposal-system",
    initialState,
    reducers: {
        clearState: (state) => {
            state.currentWindowIndex = 0;
            state.campaignId = "";
            state.selectedSocialMedia = "";
            state.selectedClientId = "";
            state.campaignName = "";
            state.addedAccounts = [];
            state.campaignContent = [{}];
            state.totalFollowers = 0;
            state.price = 0;
            state.createdDate = "";
            state.isAccountInfoOpen = {isOpen: false, account: {}};
            state.isAddInfluencerToCampaignModalOpen = false;
            state.isSelectCampaignContentItemModalOpen = {isOpen: false, instagramUsername: "", influencerId: ""};
            saveStateToLocalStorage(state);
        },
        setFullState: (state, action) => {
            state.currentWindowIndex = 0;
            state.campaignId = action.payload.campaignId;
            state.selectedSocialMedia = action.payload.selectedSocialMedia;
            state.selectedClientId = action.payload.selectedClientId;
            state.campaignName = action.payload.campaignName;
            state.addedAccounts = action.payload.addedAccounts;
            state.campaignContent = action.payload.campaignContent;
            state.totalFollowers = 0;
            state.price = 0;
            state.isAccountInfoOpen = {isOpen: false, account: {}};
            state.isAddInfluencerToCampaignModalOpen = false;
            state.isSelectCampaignContentItemModalOpen = {isOpen: false, instagramUsername: "", influencerId: ""};
            saveStateToLocalStorage(state);
        },
        setTotalFollowers: (state, action) => {
            state.totalFollowers = action.payload;
            saveStateToLocalStorage(state);
        },
        setPrice: (state, action) => {
            state.price = action.payload;
            saveStateToLocalStorage(state);
        },
        setCreatedDate: (state, action) => {
            state.createdDate = action.payload;
            saveStateToLocalStorage(state);
        },
        setCurrentWindowIndex: (state, action) => {
            state.currentWindowIndex = action.payload;
            saveStateToLocalStorage(state);
        },
        setCampaignId: (state, action) => {
            state.campaignId = action.payload;
            saveStateToLocalStorage(state);
        },
        setSelectSocialMedia: (state, action) => {
            state.selectedSocialMedia = action.payload;
            saveStateToLocalStorage(state);
        },
        setSelectedClientId: (state, action) => {
            state.selectedClientId = action.payload;
            saveStateToLocalStorage(state);
        },
        setCampaignName: (state, action) => {
            state.campaignName = action.payload;
            saveStateToLocalStorage(state);
        },
        addAccount: (state, action) => {
            state.addedAccounts.push(action.payload);
            saveStateToLocalStorage(state);
        },
        removeAccount: (state, action) => {
            state.addedAccounts = state.addedAccounts.filter((account) =>
                account.instagramUsername !== action.payload.instagramUsername ||
                account.influencerId !== action.payload.influencerId
            );
            saveStateToLocalStorage(state);
        },
        setIsAccountInfoOpen: (state, action) => {
            state.isAccountInfoOpen.isOpen = action.payload.isOpen;
            state.isAccountInfoOpen.account = action.payload.account;
            saveStateToLocalStorage(state);
        },
        setIsAddInfluencerToCampaignModalOpen: (state, action) => {
            state.isAddInfluencerToCampaignModalOpen = action.payload;
            saveStateToLocalStorage(state);
        },
        addContentToCampaign: (state, action) => {
            state.campaignContent.push(action.payload);
            saveStateToLocalStorage(state);
        },
        setContentFieldValue: (state, action) => {
            const content = state.campaignContent.find(content => content._id === action.payload.contentId);
            content[action.payload.fieldName] = action.payload.value;
            saveStateToLocalStorage(state);
        },
        removeContentItemFromCampaign: (state, action) => {
            state.campaignContent = state.campaignContent.filter((content) => content._id !== action.payload);
            saveStateToLocalStorage(state);
        },
        setIsSelectCampaignContentItemModalOpen: (state, action) => {
            state.isSelectCampaignContentItemModalOpen.isOpen = action.payload.isOpen;
            state.isSelectCampaignContentItemModalOpen.instagramUsername = action.payload.instagramUsername;
            state.isSelectCampaignContentItemModalOpen.influencerId = action.payload.influencerId;
            saveStateToLocalStorage(state);
        },
        linkCampaignContentItemToInfluencer: (state, action) => {
            const contentItemVideoLink = state.campaignContent.find(item => item.videoLink === action.payload).videoLink;

            if (contentItemVideoLink) {
                state.addedAccounts = state.addedAccounts.map(account => {
                    if (
                        account.instagramUsername === state.isSelectCampaignContentItemModalOpen.instagramUsername &&
                        account.influencerId === state.isSelectCampaignContentItemModalOpen.influencerId
                    ) {
                        return {
                            ...account,
                            selectedVideo: contentItemVideoLink,
                        };
                    }
                    return account;
                });
                saveStateToLocalStorage(state);
            } else {
                console.error("Content item not found with the given videoLink.");
            }
        },
        updateDateRequest: (state, action) => {
            const {instagramUsername, influencerId, date} = action.payload;

            const account = state.addedAccounts.find(
                account => account.instagramUsername === instagramUsername && account.influencerId === influencerId
            );

            if (account) {
                account.dateRequest = date;
                saveStateToLocalStorage(state);
            }
        },
    },
});

export const {
    clearState,
    setFullState,
    setCurrentWindowIndex,
    setTotalFollowers,
    setPrice,
    setCreatedDate,
    setCampaignId,
    setSelectSocialMedia,
    setSelectedClientId,
    setCampaignName,
    addAccount,
    removeAccount,
    setIsAccountInfoOpen,
    setIsAddInfluencerToCampaignModalOpen,
    addContentToCampaign,
    setContentFieldValue,
    removeContentItemFromCampaign,
    setIsSelectCampaignContentItemModalOpen,
    linkCampaignContentItemToInfluencer,
    updateDateRequest,
} = proposalSystemSlice.actions;

export default proposalSystemSlice.reducer;
