import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {
    setIsSelectCampaignContentItemModalOpen, updateDateRequest,
} from "../../../../../../redux/slice/proposal-system";
import watch from "../../../../../../images/icons/view 1.svg";
import editImg from "../../../../../../images/icons/edit 1.svg";
import {SelectDate} from "../../../../../form/SelectDate/SelectDate";

export const AccountClientSharedCampaignTable = () => {
    const dispatch = useDispatch();
    const totalFollowers = useSelector(state => state.proposalSystem.totalFollowers);
    const price = useSelector(state => state.proposalSystem.price);
    const addedAccounts = useSelector(state => state.proposalSystem.addedAccounts);
    const campaignContent = useSelector(state => state.proposalSystem.campaignContent);
    console.log(addedAccounts)

    const getContentItemField = (contentItemFieldName, selectedVideoLink) => {
        const contentItem = campaignContent.find(contentItem => contentItem.videoLink === selectedVideoLink);
        return contentItem ? contentItem[contentItemFieldName] : "";
    };

    const handleDateChange = (date, instagramUsername, influencerId) => {
        dispatch(updateDateRequest({date, instagramUsername, influencerId}));
    };

    const getDateInputValue = (instagramUsername, influencerId) => {
        const account = addedAccounts.find((account) => account.instagramUsername === instagramUsername && account.influencerId === influencerId);

        if (account && account.dateRequest && account.dateRequest !== "ASAP") {
            return account.dateRequest.slice(4);
        }

        return "";
    };

    const getSelectedOption = (instagramUsername, influencerId) => {
        return addedAccounts.find((account) => account.instagramUsername === instagramUsername && account.influencerId === influencerId).dateRequest.replace(/[0-9.]/g, "").trim();
    };

    const handleDateInputChange = (e, instagramUsername, influencerId) => {
        let value = e.target.value.replace(/\D/g, "");
        if (value.length > 6) value = value.slice(0, 6);

        const formattedValue = value.replace(/(\d{2})(?=\d)/g, "$1.");

        const account = addedAccounts.find((account) => account.instagramUsername === instagramUsername && account.influencerId === influencerId);

        if (account) {
            const firstPart = account.dateRequest.slice(0, 3);
            dispatch(updateDateRequest({date: `${firstPart} ${formattedValue}`, instagramUsername, influencerId}));
        }
    };

    return (<table className="proposal-system-influencers-table" style={{boxShadow: 'none', marginRight: 58, borderRadius: '30px 30px 0 0'}}>
        <thead className="proposal-system-influencers-table-header">
        <tr>
            <th>Networks</th>
            <th>Total Followers</th>
            <th>Date Post</th>
            <th>Video</th>
            <th>Post Description</th>
            <th>Story Tag</th>
            <th>Story Link</th>
            <th>Genres</th>
            <th>Top 5 Countries</th>
            <th>Special Requests</th>
        </tr>
        </thead>
        <tbody className="proposal-system-influencers-table-body">
        {addedAccounts.map((account, index) => (<tr key={index}>
            <td className="logo-username">
                <div id="logo">
                    <img src={account.logo} alt=""/>
                </div>
                <p>{account.instagramUsername}</p>
            </td>
            <td className="followers-number">{account.followersNumber}</td>
            <td className="date" style={{minWidth: '150px'}}>
                <div id="first">
                    <div id="second"
                         style={{paddingLeft: getSelectedOption(account.instagramUsername, account.influencerId) !== "ASAP" ? 10 : 0}}>
                        <SelectDate
                            selectedOption={getSelectedOption(account.instagramUsername, account.influencerId)}
                            selectOption={(selectedDate) => handleDateChange(selectedDate, account.instagramUsername, account.influencerId)}
                            listOfOptions={["ASAP", "BEF", "AFT"]}/>
                    </div>
                    {account.dateRequest !== "ASAP" && <input
                        type="text"
                        value={getDateInputValue(account.instagramUsername, account.influencerId)}
                        onChange={(e) => handleDateInputChange(e, account.instagramUsername, account.influencerId)}
                        maxLength="8"
                        placeholder="DD.MM.YY"
                        pattern="\d{2}\.\d{2}\.\d{2}"
                    />}
                </div>
            </td>
            <td className="select-content">
                <div className="container">
                    <div>
                        <button onClick={() => {
                            if (account.selectedVideo) {
                                window.open(account.selectedVideo, "_blank", "noopener,noreferrer");
                            }
                        }}>
                            <img src={watch} alt=""/>
                        </button>
                    </div>
                </div>
            </td>
            <td className="post-description" style={{minWidth: 300, maxWidth: 500, whiteSpace: "wrap"}}>
                <div>
                    {account.selectedVideo ? getContentItemField("postDescription", account.selectedVideo) : ""}
                </div>
            </td>
            <td className="story-tag" style={{minWidth: 200, maxWidth: 500, whiteSpace: "wrap"}}>
                <div>
                    {account.selectedVideo ? getContentItemField("storyTag", account.selectedVideo) : ""}
                </div>
            </td>
            <td className="story-link" style={{minWidth: 200, maxWidth: 500, whiteSpace: "wrap"}}>
                {account.selectedVideo ?
                    <a href={getContentItemField("swipeUpLink", account.selectedVideo)} target="_blank"
                       rel="noreferrer"> {getContentItemField("swipeUpLink", account.selectedVideo).slice(0, 30)}...</a> : ""}
            </td>
            <td className="genres">
                {account.genres.map((genre, index) => (
                    <span>{genre} {index !== account.genres.length - 1 ? "- " : ""}</span>))}
            </td>
            <td className="countries">
                {account.countries.map((country, index) => (<span id="country">{country.country} <span
                    id="percentage">{country.percentage}%</span> {index !== account.countries.length - 1 ? "- " : ""}</span>))}
            </td>
            <td className="special-requests" style={{minWidth: 200, maxWidth: 500, whiteSpace: "wrap"}}>
                <div>
                    {account.selectedVideo ? getContentItemField("specialWishes", account.selectedVideo) : ""}
                </div>
            </td>
        </tr>))}
        </tbody>
        <tfoot className="proposal-system-influencers-table-footer">
        <tr>
            <td>PRICE: {price}€</td>
            <td>{totalFollowers}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        </tfoot>
    </table>)
}
