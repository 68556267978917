import React, {useEffect, useState} from 'react'
import TitleSection from "../../../../TitleSection";
import plusIcon from "../../../../../images/icons/plus 2.svg";
import {CampaignItem} from "./components/form/CampaignItem";
import {useNavigate} from "react-router-dom";
import backBtn from "../../../../../images/icons/arrow.svg";
import {useDispatch} from "react-redux";
import {clearState, setCampaignId} from "../../../../../redux/slice/proposal-system";
import {NotificationContainer, NotificationManager} from "react-notifications";
import Loading from "../../../../form/PageLoading/pageLoading";
import axios from "axios";
import {generateMongoObjectId} from "../../../../../utils/generateId";
import ModalWindow from "../../../../ModalWindow";

export const AdminManageProposalSystem = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [campaigns, setCampaigns] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            const result = await axios.get(`${process.env.REACT_APP_SERVER}/proposal-system/get-all-proposal-campaigns`);
            if (result.data.status === 200) {
                setCampaigns(result.data.proposalCampaigns);
            } else {
                throw new Error(result.data.message);
            }
        } catch (error) {
            console.log("Error: ", error);
            NotificationManager.error("Error", "Error while fetching data");
        }
    };

    const handleBackButton = () => {
        dispatch(clearState());
        navigate('/admin/home');
    };

    const handleCreateNewCampaign = () => {
        dispatch(setCampaignId(generateMongoObjectId()));
        navigate('/admin/proposal-system/manage-campaign');
    };
    
    return (
        <section className="proposal-system-page">
            <div className="admin-title-section">
                <button onClick={() => handleBackButton()}>
                    <img src={backBtn} style={{transform: "rotate(180deg)"}}/>
                </button>
                <TitleSection title='Proposal' span='System'/>
            </div>

            <div className="create-new-campaign-button"
                 onClick={() => handleCreateNewCampaign()}>
                <img src={plusIcon} alt="Create new campaign"/>
                <p>CREATE NEW CAMPAIGN</p>
            </div>

            <div className="horizontal-line"></div>

            {campaigns.length > 0 ? campaigns.map((item, index) => (
                <div className="proposal-system-campaigns-list-container">
                    <CampaignItem campaign={item}/>
                </div>
            )) : <Loading/>}

            <NotificationContainer/>
        </section>
    )
}
