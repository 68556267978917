import React, {useEffect, useState} from "react";
import AccountClientOffers from "./AccountClientOffers";
import AccountClientPostContent from "./AccountClientPostContent";
import AccountClientPayment from "./AccountClientPayment";
import AccountClientDetailsQuestion from "./AccountClientDetailsQuestion";
import {useSelector} from "react-redux";
import AccountClientCampaignStrategy from "./AccountClientCampaignStrategy";
import AccountClientSaveAndEstimateCampaign from "./AccountClientSaveAndEstimateCampaign";

const CreatePromo = () => {
    const currentWindow = useSelector((state) => state.createPromo.currentWindow);

    return (
        <>
            {
                [
                    <AccountClientOffers/>,
                    <AccountClientDetailsQuestion/>,
                    <AccountClientSaveAndEstimateCampaign/>,
                    <AccountClientPostContent/>,
                    <AccountClientCampaignStrategy/>,
                    <AccountClientPayment/>,
                ][currentWindow]
            }
        </>
    );
};

export default CreatePromo;
