import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {
    clearState,
    removeAccount,
    setIsAccountInfoOpen,
    setIsAddInfluencerToCampaignModalOpen, setIsSelectCampaignContentItemModalOpen, updateDateRequest
} from "../../../../../../../redux/slice/proposal-system";
import watch from "../../../../../../../images/icons/view 1.svg";
import plusImg from "../../../../../../../images/icons/plus 2.svg";
import editImg from "../../../../../../../images/icons/edit 1.svg";
import {SelectDate} from "../../../../../../form/SelectDate/SelectDate";
import {NotificationManager} from "react-notifications";
import axios from "axios";
import StandardButton from "../../../../../../form/StandardButton";
import {useNavigate} from "react-router-dom";
import closeImg from "../../../../../../../images/icons/close.svg";
import {returnMusicStyles} from "../../../../../../../utils/musicStyles";

export const ManageInfluencersInCampaignTable = ({influencers}) => {
    //TODO: Использовать функцию (из typeOfSocialAccounts) которая будет возвращать названия полей для Campaign Details в зависимости от соц сети
    const addedAccounts = useSelector(state => state.proposalSystem.addedAccounts);
    const campaignContent = useSelector((state) => state.proposalSystem.campaignContent);
    const campaignName = useSelector(state => state.proposalSystem.campaignName);
    const selectedClientId = useSelector(state => state.proposalSystem.selectedClientId);
    const campaignId = useSelector(state => state.proposalSystem.campaignId);
    const selectedSocialMedia = useSelector(state => state.proposalSystem.selectedSocialMedia);
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [addedFullAccounts, setAddedFullAccounts] = useState([]);

    useEffect(() => {
        if (addedAccounts.length === 0) return;

        const updatedAccounts = addedAccounts.map(account => {
            const existingFullAccount = addedFullAccounts.find(
                fullAccount =>
                    fullAccount.influencerId === account.influencerId &&
                    fullAccount.instagramUsername === account.instagramUsername
            );

            if (existingFullAccount) {
                return {
                    ...existingFullAccount,
                    dateRequest: account.dateRequest || "ASAP",
                    selectedVideo: account.selectedVideo || "",
                };
            }

            const influencer = influencers.find(
                influencer =>
                    influencer.influencerId === account.influencerId &&
                    influencer.instagramUsername === account.instagramUsername
            );

            if (influencer) {
                return {
                    ...influencer,
                    dateRequest: account.dateRequest || "ASAP",
                    selectedVideo: account.selectedVideo || "",
                };
            }

            return null;
        }).filter(Boolean);

        setAddedFullAccounts(updatedAccounts);
    }, [addedAccounts, influencers]);

    const saveProposalCampaign = async () => {
        if (!campaignName) {
            NotificationManager.error("Please enter a campaign name", "Campaign Name",3000);
            return;
        }
        
        try {
            const result = await axios.put(`${process.env.REACT_APP_SERVER}/proposal-system/save-proposal-campaign`, {
                campaignId: campaignId,
                campaignName: campaignName,
                clientId: selectedClientId,
                selectInfluencers: addedFullAccounts.map((account) => ({
                    influencerId: account.influencerId,
                    instagramUsername: account.instagramUsername,
                    logo: account.logo,
                    price: account.price,
                    publicPrice: account.publicPrice,
                    dateRequest: account.dateRequest,
                    selectedVideo: account.selectedVideo,
                    followersNumber: parseInt(account.followersNumber),
                    genres: returnMusicStyles(account.musicStyle, account.musicStyleOther, account.musicSubStyles) || [],
                    countries: account.countries,
                })) || [],
                videos: campaignContent || [],
                price: addedFullAccounts.reduce((acc, current) => {
                    const price = current.price ? current.price.toString().replace(/\D/g, '') : '0';
                    return acc + parseInt(price, 10);
                }, 0),
                publicPrice: calculateTotalPrice(),
                totalFollowers: calculateTotalFollowers(),
                socialMedia: selectedSocialMedia,
            })
            
            if (result.data.status === 200 || result.data.status === 201) {
                dispatch(clearState());
                navigate("/admin/proposal-system");
            } else {
                throw new Error(result.data.message);
            }
        } catch (err) {
            console.error("Error saving campaign:", err);
            NotificationManager.error(err.message, "Error", 3000);
        }
    };

    const handleRemoveAccount = (instagramUsername, influencerId) => {
        dispatch(removeAccount({instagramUsername, influencerId}));
        setAddedFullAccounts(prev => prev.filter(account =>
            account.instagramUsername !== instagramUsername || account.influencerId !== influencerId
        ));
    };

    const calculateTotalFollowers = () => {
        return addedFullAccounts.reduce((acc, current) => acc + parseInt(current.followersNumber), 0);
    };

    const calculateTotalPrice = () => {
        return addedFullAccounts.reduce((acc, current) => acc + parseInt(current.publicPrice), 0);
    };

    const getContentItemField = (contentItemFieldName, selectedVideoLink) => {
        const contentItem = campaignContent.find(contentItem => contentItem.videoLink === selectedVideoLink);
        return contentItem ? contentItem[contentItemFieldName] : "";
    };

    const handleDateChange = (date, instagramUsername, influencerId) => {
        dispatch(updateDateRequest({date, instagramUsername, influencerId}));
    };

    const getDateInputValue = (instagramUsername, influencerId) => {
        const account = addedFullAccounts.find(
            (account) =>
                account.instagramUsername === instagramUsername &&
                account.influencerId === influencerId
        );

        if (account && account.dateRequest && account.dateRequest !== "ASAP") {
            return account.dateRequest.slice(4);
        }

        return "";
    };

    const getSelectedOption = (instagramUsername, influencerId) => {
        return addedAccounts.find(
            (account) => account.instagramUsername === instagramUsername && account.influencerId === influencerId
        ).dateRequest.replace(/[0-9.]/g, "").trim();
    };

    const handleDateInputChange = (e, instagramUsername, influencerId) => {
        let value = e.target.value.replace(/\D/g, "");
        if (value.length > 6) value = value.slice(0, 6);

        const formattedValue = value.replace(/(\d{2})(?=\d)/g, "$1.");

        const account = addedAccounts.find(
            (account) =>
                account.instagramUsername === instagramUsername &&
                account.influencerId === influencerId
        );

        if (account) {
            const firstPart = account.dateRequest.slice(0, 3);
            dispatch(updateDateRequest({date: `${firstPart} ${formattedValue}`, instagramUsername, influencerId}));
        }
    };

    return (
        <div>
            <table className="proposal-system-influencers-table">
                <thead className="proposal-system-influencers-table-header">
                <tr>
                    <th>Networks</th>
                    <th>Total Followers</th>
                    <th>Account Info</th>
                    <th>Date Post</th>
                    <th>Video</th>
                    <th>Post Description</th>
                    <th>Story Tag</th>
                    <th>Story Link</th>
                    <th>Special Requests</th>
                </tr>
                </thead>
                <tbody className="proposal-system-influencers-table-body">
                {addedFullAccounts.map((account, index) => (
                    <tr key={index}>
                        <td className="logo-username">
                            <div id="image" onClick={() => handleRemoveAccount(account.instagramUsername, account.influencerId)}>
                                <img src={closeImg} alt=""/>
                            </div>
                            <p>{account.instagramUsername}</p>
                        </td>
                        <td className="followers-number">{account.followersNumber}</td>
                        <td className="account-info">
                            <div>
                                <button onClick={() => {
                                    dispatch(setIsAccountInfoOpen({isOpen: true, account}));
                                }}>
                                    <img src={watch} alt=""/>
                                </button>
                            </div>
                        </td>
                        <td className="date">
                            <div id="first">
                                <div id="second"
                                     style={{paddingLeft: getSelectedOption(account.instagramUsername, account.influencerId) !== "ASAP" ? 10 : 0}}>
                                    <SelectDate
                                        selectedOption={getSelectedOption(account.instagramUsername, account.influencerId)}
                                        selectOption={(selectedDate) => handleDateChange(selectedDate, account.instagramUsername, account.influencerId)}
                                        listOfOptions={["ASAP", "BEF", "AFT"]}/>
                                </div>
                                {account.dateRequest !== "ASAP" &&
                                    <input
                                        type="text"
                                        value={getDateInputValue(account.instagramUsername, account.influencerId)}
                                        onChange={(e) => handleDateInputChange(e, account.instagramUsername, account.influencerId)}
                                        maxLength="8"
                                        placeholder="DD.MM.YY"
                                        pattern="\d{2}\.\d{2}\.\d{2}"
                                    />
                                }
                            </div>
                        </td>
                        <td className="select-content">
                            <div className="container">
                                <div>
                                    <button
                                        onClick={() => dispatch(setIsSelectCampaignContentItemModalOpen({
                                            isOpen: true,
                                            instagramUsername: account.instagramUsername,
                                            influencerId: account.influencerId
                                        }))}>
                                        <img src={editImg} alt=""/>
                                    </button>
                                </div>
                                <div>
                                    <button onClick={() => {
                                        if (account.selectedVideo) {
                                            window.open(account.selectedVideo, "_blank", "noopener,noreferrer");
                                        }
                                    }}>
                                        <img src={watch} alt=""/>
                                    </button>
                                </div>
                            </div>
                        </td>
                        <td className="post-description">
                            <div>
                                {account.selectedVideo ? getContentItemField("postDescription", account.selectedVideo) : ""}
                            </div>
                        </td>
                        <td className="story-tag">
                            <div>
                                {account.selectedVideo ? getContentItemField("storyTag", account.selectedVideo) : ""}
                            </div>
                        </td>
                        <td className="story-link">
                            {account.selectedVideo ?
                                <a href={getContentItemField("swipeUpLink", account.selectedVideo)} target="_blank"
                                   rel="noreferrer"> {getContentItemField("swipeUpLink", account.selectedVideo).slice(0, 13)}...</a> : ""}
                        </td>
                        <td className="special-requests">
                            <div>
                                {account.selectedVideo ? getContentItemField("specialWishes", account.selectedVideo) : ""}
                            </div>
                        </td>
                    </tr>
                ))}
                <tr className="add-new-account-tr">
                    <td onClick={() => dispatch(setIsAddInfluencerToCampaignModalOpen(true))}>
                        <div className="add-new-account-tr-plus">
                            <img src={plusImg} alt=""/>
                        </div>
                        <p>Add Influencer</p>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                </tbody>
                <tfoot className="proposal-system-influencers-table-footer">
                <tr>
                    <td>PRICE: {calculateTotalPrice()}€</td>
                    <td>{calculateTotalFollowers()}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                </tfoot>
            </table>
            <div className="proposal-system-influencers-buttons">
                <StandardButton text="Save" onClick={() => saveProposalCampaign()}/>
            </div>
        </div>
    )
}
