import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from "react-router-dom";
import {NotificationContainer, NotificationManager} from "react-notifications";
import axios from "axios";
import {decodeAndDecrypt} from "../../../../../../utils/crypt";
import Loading from "../../../../../form/PageLoading/pageLoading";
import {useDispatch, useSelector} from "react-redux";
import {
    clearState,
    setCampaignName, setCreatedDate,
    setFullState,
    setPrice,
    setTotalFollowers
} from "../../../../../../redux/slice/proposal-system";
import TitleSection from "../../../../../TitleSection";
import arrow from "../../../../../../images/icons/arrow.svg";
import shareIcon from "../../../../../../images/icons/Share.svg";
import {
    ManageInfluencersInCampaignTable
} from "../../../admin/AdminProposalSystem/components/form/ManageInfluencersInCampaignTable";
import {NewCampaignDetails} from "../../../../../form/NewCampaignDetails/NewCampaignDetails";
import {AccountClientSharedCampaignTable} from "./AccountClientSharedCampaignTable";

export const AccountClientSharedCampaignPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const campaignId = decodeAndDecrypt(useParams().campaignId, process.env.REACT_APP_PROPOSAL_SHARE_SECRET_KEY);
    const campaignName = useSelector(state => state.proposalSystem.campaignName);
    const totalFollowers = useSelector(state => state.proposalSystem.totalFollowers);
    const price = useSelector(state => state.proposalSystem.price);
    const addedAccounts = useSelector(state => state.proposalSystem.addedAccounts);
    const campaignContent = useSelector(state => state.proposalSystem.campaignContent);
    const createdDate = useSelector(state => state.proposalSystem.createdDate);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getData();
    }, [campaignId]);

    const getData = async () => {
        try {
            const result = await axios.get(`${process.env.REACT_APP_SERVER}/proposal-system/get-proposal-campaign/${campaignId}`);

            if (result.data.status === 200) {
                setIsLoading(false);
                dispatch(setFullState({
                    campaignId: campaignId,
                    selectedSocialMedia: result.data.proposalCampaign.socialMedia,
                    selectedClientId: result.data.proposalCampaign.clientId,
                    campaignName: result.data.proposalCampaign.campaignName,
                    addedAccounts: result.data.proposalCampaign.selectInfluencers,
                    campaignContent: result.data.proposalCampaign.videos
                }))
                dispatch(setTotalFollowers(result.data.proposalCampaign.totalFollowers));
                dispatch(setPrice(result.data.proposalCampaign.publicPrice));
                dispatch(setCreatedDate(result.data.proposalCampaign.createdAt));
                return;
            }

            NotificationManager.error('Failed to load campaign', 'Error', 3000);
        } catch (err) {
            NotificationManager.error('Failed to load campaign', 'Error', 3000);
            console.error(err);
        }
    };

    const handleCopyLink = () => {
        try {
            navigator.clipboard.writeText(window.location.href);
            NotificationManager.success('Link copied to clipboard', '', 3000);
        } catch (err) {
            NotificationManager.error('Failed to copy link to clipboard');
        }
    };

    if (isLoading) return <Loading/>;
    return (
        <section className="proposal-system">
            <div className="proposal-system-title-with-btn">
                <div id="back-btn" onClick={() => {
                    dispatch(clearState());
                    navigate("/account/client")
                }}>
                    <img src={arrow} alt="" style={{transform: "rotate(180deg)"}}/>
                </div>
                <div id="title">
                    <TitleSection title="Campaign" span="strategy"/>
                </div>
                <div id="share" onClick={() => handleCopyLink()}>
                    <img src={shareIcon} alt={""}/>
                </div>
            </div>

            <div className="proposal-system-influencers">
                <div className="details">
                    <div className="details-campaign-name">
                        <input
                            type="text"
                            placeholder="Campaign Name"
                            value={campaignName}
                            onChange={(e) => dispatch(setCampaignName(e.target.value))}
                        />
                    </div>

                    <div>
                        <NewCampaignDetails date={createdDate} totalFollowers={totalFollowers} price={price}
                                            countOfAccounts={addedAccounts.length}
                                            countOfVideos={campaignContent.length}/>
                    </div>
                </div>
            </div>

            <div className="proposal-system-shared-campaign-table-container">
                {addedAccounts.length > 0 && <AccountClientSharedCampaignTable/>}
            </div>

            <NotificationContainer/>
        </section>
    )
}
