import { createSlice } from "@reduxjs/toolkit";

const saveStateToLocalStorage = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem("createPromoState", serializedState);
    } catch (e) {
        console.error("Error saving state to localStorage:", e);
    }
};

const loadStateFromLocalStorage = () => {
    try {
        const serializedState = localStorage.getItem("createPromoState");
        // console.log("serializedState", serializedState);
        return serializedState ? JSON.parse(serializedState) : {
            currentWindow: 0,
            data: {
                currency: "€",
                amount: 0,
                selectPrice: {
                    variant: 0,
                    price: 0,
                },
                selectInfluencers: [],
                selectPriceInfluencers: [],
                campaignName: "",
                paymentType: "",
                paymentStatus: "wait",
                createdAt: "",
                videos: [
                    {
                        videoLink: "",
                        postDescription: "",
                        storyTag: " ",
                        swipeUpLink: " ",
                        specialWishes: " ",
                    },
                ],
                socialMedia: "",
                canGoBack: true,
            },
        };
    } catch (e) {
        console.error("Error loading state from localStorage:", e);
        return {
            currentWindow: 0,
            data: {
                currency: "€",
                amount: 0,
                selectPrice: {
                    variant: 0,
                    price: 0,
                },
                selectInfluencers: [],
                selectPriceInfluencers: [],
                campaignName: "",
                paymentType: "",
                paymentStatus: "wait",
                createdAt: "",
                videos: [
                    {
                        videoLink: "",
                        postDescription: "",
                        storyTag: " ",
                        swipeUpLink: " ",
                        specialWishes: " ",
                    },
                ],
                socialMedia: "",
                canGoBack: true,
            },
        };
    }
};

const initialState = loadStateFromLocalStorage();

export const createPromoSlice = createSlice({
    name: "create-promo",
    initialState,
    reducers: {
        setCurrentWindow: (state, action) => {
            state.currentWindow = action.payload;
            saveStateToLocalStorage(state);
        },
        setSelectCurrency: (state, action) => {
            state.data.currency = action.payload;
            saveStateToLocalStorage(state);
        },
        setSelectPrice: (state, action) => {
            state.data.selectPrice = action.payload;
            saveStateToLocalStorage(state);
        },
        setSelectPriceInfluencers: (state, action) => {
            state.data.selectPriceInfluencers = action.payload;
            saveStateToLocalStorage(state);
        },
        setSelectAmount: (state, action) => {
            state.data.amount = action.payload;
            saveStateToLocalStorage(state);
        },
        setSelectInfluencer: (state, action) => {
            state.data.selectInfluencers = action.payload;
            saveStateToLocalStorage(state);
        },
        updateSelectInfluencer: (state, action) => {
            state.data.selectInfluencers = action.payload;
            saveStateToLocalStorage(state);
        },
        setCampaignName: (state, action) => {
            state.data.campaignName = action.payload;
            saveStateToLocalStorage(state);
        },
        setPaymentType: (state, action) => {
            state.data.paymentType = action.payload;
            saveStateToLocalStorage(state);
        },
        setCreatedAt: (state, action) => {
            state.data.createdAt = action.payload;
            saveStateToLocalStorage(state);
        },
        addVideo: (state, action) => {
            const newVideo = {
                ...action.payload,
                forInfluencers: [],
            };
            state.data.videos.push(newVideo);
            saveStateToLocalStorage(state);
        },
        updateVideo: (state, action) => {
            const { index, videoData } = action.payload;
            if (state.data.videos[index]) {
                state.data.videos[index] = {
                    ...state.data.videos[index], 
                    ...videoData 
                };
            }
            saveStateToLocalStorage(state);
        },
        removeVideo: (state, action) => {
            const index = action.payload;
            if (state.data.videos[index]) {
                state.data.videos.splice(index, 1);
            }
            saveStateToLocalStorage(state);
        },
        setClearCampaignDetailsCreatePromo: (state, action) => {
            state.data.campaignName = "";
            state.data.videos = [
                {
                    videoLink: "",
                    postDescription: "",
                    storyTag: "",
                    swipeUpLink: "",
                    specialWishes: "",
                },
            ];
            state.data.createdAt = "";
            saveStateToLocalStorage(state);
        },
        setClearFormCreatePromo: (state) => {
            state.data = {
                currency: "€",
                amount: 0,
                selectPrice: {
                    variant: 0,
                    price: 0,
                },
                selectInfluencers: [],
                selectPriceInfluencers: [],
                paymentType: "",
                paymentStatus: "wait",
                createdAt: "",
                videos: [
                    {
                        videoLink: "",
                        postDescription: "",
                        storyTag: " ",
                        swipeUpLink: " ",
                        specialWishes: " ",
                    },
                ],
                socialMedia: "",
                canGoBack: true,
            };
            saveStateToLocalStorage(state);
        },
        setSocialMedia: (state, action) => {
            state.data.socialMedia = action.payload;
            saveStateToLocalStorage(state);
        },
        setCanGoBack: (state, action) => {
            state.data.canGoBack = action.payload;
            saveStateToLocalStorage(state);
        },
        setFullState: (state, action) => {
            state.currentWindow = action.payload.currentWindow;
            state.data.currency = action.payload.currency;
            state.data.amount = action.payload.amount;
            state.data.selectPrice = action.payload.selectPrice;
            state.data.selectInfluencers = action.payload.selectInfluencers;
            state.data.selectPriceInfluencers = action.payload.selectPriceInfluencers;
            state.data.campaignName = action.payload.campaignName;
            state.data.paymentType = action.payload.paymentType;
            state.data.paymentStatus = action.payload.paymentStatus;
            state.data.createdAt = action.payload.createdAt;
            state.data.videos = action.payload.videos;
            state.data.socialMedia = action.payload.socialMedia;
            state.data.canGoBack = action.payload.canGoBack;
            saveStateToLocalStorage(state);
        }
    },
});

export const {
    setCurrentWindow,
    setSelectCurrency,
    setSelectPrice,
    setSelectAmount,
    setSelectInfluencer,
    updateSelectInfluencer,
    setCampaignName,
    setPaymentType,
    setCreatedAt,
    addVideo,
    updateVideo,
    removeVideo,
    setClearFormCreatePromo,
    setClearCampaignDetailsCreatePromo,
    setSelectPriceInfluencers,
    setSocialMedia,
    setCanGoBack,
    setFullState,
} = createPromoSlice.actions;

export default createPromoSlice.reducer;
