import React, {useEffect, useState} from 'react'
import TitleSection from "../../../../../TitleSection";
import Loading from "../../../../../form/PageLoading/pageLoading";
import {useDispatch, useSelector} from "react-redux";
import {addAccount, clearState, setCampaignName, setSelectedOffer} from "../../../../../../redux/slice/proposal-system";
import {ManageInfluencersInCampaignTable} from "./form/ManageInfluencersInCampaignTable";
import shareIcon from "../../../../../../images/icons/Share.svg";
import {NotificationManager} from "react-notifications";
import {encryptAndEncode} from "../../../../../../utils/crypt";
import arrow from "../../../../../../images/icons/arrow.svg";
import {useNavigate} from "react-router-dom";
import {OffersList} from "../../../../../form/OffersList/OffersList";

export const SelectInfluencers = ({influencers, offers}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [isOffersHidden, setIsOffersHidden] = useState(false);

    const campaignName = useSelector(state => state.proposalSystem.campaignName);
    const campaignId = useSelector(state => state.proposalSystem.campaignId);
    const selectedOffer = useSelector(state => state.proposalSystem.selectedOffer)
    const addedAccounts = useSelector(state => state.proposalSystem.addedAccounts);

    useEffect(() => {
        if (!influencers || !influencers.length > 0) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }

    }, [influencers]);

    const handleShareClick = () => {
        try {
            let encryptedCampaignId = encryptAndEncode(campaignId, process.env.REACT_APP_PROPOSAL_SHARE_SECRET_KEY);
            let shareLink = `${process.env.REACT_APP_CLIENT}/proposal-system/shared-campaign/${encryptedCampaignId}`;
            navigator.clipboard.writeText(shareLink);
            NotificationManager.success('Link copied to clipboard', '', 3000);
        } catch (err) {
            NotificationManager.error('Failed to copy link to clipboard');
        }
    };

    const selectOffer = (id, genre) => {
        try {
            dispatch(setSelectedOffer({id, genre}));

            let influencersFromSelectedOffer = [];

            if (genre === "") {
                influencersFromSelectedOffer = offers
                    .find(offer => offer.id === id).connectInfluencer
                    .map(influencerAccFromOffer => ({
                        influencerId: influencerAccFromOffer.influencerId,
                        instagramUsername: influencerAccFromOffer.instagramUsername,
                    })) || [];
            } else {
                influencersFromSelectedOffer = offers
                    .find(offer => offer.id === id).musicStyles
                    .find(style => style.genres[0] === genre).connectInfluencer
                    .map(influencerAccFromOffer => ({
                        influencerId: influencerAccFromOffer.influencerId,
                        instagramUsername: influencerAccFromOffer.instagramUsername,
                    })) || [];
            }

            influencersFromSelectedOffer
                .filter(influencer => !addedAccounts.some(account => account.influencerId === influencer.influencerId && account.instagramUsername === influencer.instagramUsername))
                .forEach((influencer) => dispatch(addAccount({...influencer, dateRequest: "ASAP"})));
        } catch (err) {
            console.error("Error in selectOffer:", err);
            NotificationManager.error("Failed to select offer");
        }
    };


    if (isLoading) return <Loading/>;
    return (
        <section className="proposal-system-component">
            <div className="proposal-system-title-with-btn" style={{width: "100%"}}>
                <div id="back-btn" onClick={() => {
                    navigate("/admin/proposal-system")
                }}>
                    <img src={arrow} alt="" style={{transform: "rotate(180deg)"}}/>
                </div>
                <div id="title">
                    <TitleSection title={"Campaign"} span={"Strategy"}/>
                </div>
                <div id="share" onClick={() => handleShareClick()}>
                    <img src={shareIcon} alt={""}/>
                </div>
            </div>

            <div className="proposal-system-influencers">
                <div className="details">
                    <div className="details-campaign-name">
                        <input
                            type="text"
                            placeholder="Campaign Name"
                            value={campaignName}
                            onChange={(e) => dispatch(setCampaignName(e.target.value))}
                        />
                    </div>
                </div>

                <button className="proposal-system-hide-offers-btn" onClick={() => setIsOffersHidden(!isOffersHidden)}>
                    {isOffersHidden ? "Show Offers" : "Hide Offers"}
                </button>
                <div className={`proposal-system-offers-list-container ${!isOffersHidden ? "visible" : ""}`}>
                    {offers.length > 0 ?
                        <OffersList offers={offers} selectOffer={selectOffer} selectedOffer={selectedOffer}/> :
                        <Loading/>}
                </div>

                <div className="proposal-system-influencers-table-container">
                    <ManageInfluencersInCampaignTable influencers={influencers}/>
                </div>
            </div>
        </section>
    )
}
